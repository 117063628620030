import { type RegExpObject, type ValidationType } from "@fwa/src/types";

/*
Util function for making a delay and then a thenable for delaying promises,
useful when waiting for validation before submission
*/
export const delay = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, ms));

export const removeEmptyStringAttributes = (
  obj: Record<string, string | number | null | undefined>,
) =>
  Object.entries(obj)
    .filter(([, v]) => v !== "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const validate = (
  value: string,
  validation: ValidationType,
  setValidationMessage: (msg: string) => void,
): boolean => {
  if (!validation) return true;
  if (typeof validation.required !== "undefined" && validation.required) {
    if (!value.length) {
      setValidationMessage(`This field is required`);
      return false;
    }
  }
  if (
    typeof validation.type !== "undefined" &&
    validation.type === "number" &&
    Number.isNaN(Number(value))
  ) {
    setValidationMessage("This value must be a number");
    return false;
  }
  if (
    typeof validation.type !== "undefined" &&
    validation.type === "money" &&
    !RegexMoney.pattern.test(value)
  ) {
    setValidationMessage(RegexMoney.message);
    return false;
  }
  if (typeof validation.minValue !== "undefined") {
    if (
      validation.minValue &&
      (Number.isNaN(Number(value)) || Number(value) < validation.minValue)
    ) {
      setValidationMessage(
        `Minimum value is ${validation.minValue?.toString() || ""}`,
      );
      return false;
    }
  }
  if (typeof validation.maxValue !== "undefined") {
    if (
      validation.maxValue &&
      (Number.isNaN(Number(value)) || Number(value) > validation.maxValue)
    ) {
      setValidationMessage(
        `Maximum value is ${validation.maxValue?.toString() || ""}`,
      );
      return false;
    }
  }
  if (typeof validation.minLength !== "undefined") {
    if (
      validation.minLength &&
      value.length > 0 &&
      value.length < validation.minLength
    ) {
      setValidationMessage(
        `This field should be at least ${validation.minLength.toString()} characters`,
      );
      return false;
    }
  }
  if (typeof validation.maxLength !== "undefined") {
    if (validation.maxLength && value.length > validation.maxLength) {
      setValidationMessage(
        `This field should be at most ${validation.maxLength.toString()} characters`,
      );
      return false;
    }
  }
  if (typeof validation.regex !== "undefined") {
    const regexObject: RegExpObject = validation.regex;
    if (!value.match(regexObject.pattern)) {
      setValidationMessage(regexObject.message);
      return false;
    }
  }
  setValidationMessage("");
  return true;
};

export const sanitiseWhiteSpace = (text: string) => {
  // u00a0 = no-break space
  const trimmedText = text?.replace(/\u00a0/g, " ").trim() || "";
  const textWithPredictableSpacing = trimmedText
    .replace(/(?:\r\n|\r|\n)/g, "Ł")
    .replace(/Ł/g, "\r\n");
  return textWithPredictableSpacing;
};

// we are keeping escape chars in square brackets because we want the same regex to be robust in all languages and different flavours need it.
/* eslint-disable no-useless-escape */

export const sanitiseNames = (name: string) =>
  name
    .trim()
    .replace(/\s+/g, " ")
    .replace(/[^\s\-\'.]+[\s\-\'.]*/g, (word) => {
      const titleCaseWord =
        word[0].toUpperCase() + word.substr(1).toLowerCase();
      const macRegex = /^ma?c(.)/i;
      const matchMcOrMac = macRegex.exec(word);
      if (matchMcOrMac) {
        return (
          titleCaseWord.slice(0, matchMcOrMac[0].length - 1) +
          matchMcOrMac[1] +
          titleCaseWord.slice(matchMcOrMac[0].length)
        );
      }
      return titleCaseWord;
    });

export const sanitiseUkPostCode = (postCode: string) => {
  const noSpaceString = postCode.replace(/ /g, "").toLocaleUpperCase();
  const withSpaceString = `${noSpaceString.substring(
    0,
    noSpaceString.length - 3,
  )} ${noSpaceString.substring(
    noSpaceString.length - 3,
    noSpaceString.length,
  )}`;

  return withSpaceString;
};

export const sanitiseIfUkPostCode = (postCode: string) => {
  if (regexUkPostcode.pattern.exec(postCode)) {
    return sanitiseUkPostCode(postCode);
  }

  return postCode;
};
export const calcLength = (val: string) => {
  if (!val) return 0;
  let value = val;
  if (value.endsWith("\n")) {
    value = value.slice(0, -1);
  }
  return value.replace(/(?:\r\n|\r|\n)/g, "Ł").replace(/Ł/g, "\r\n").length;
};

export const regexFornameExclusions: RegExpObject = {
  message: "Please only enter a formal forname.",
  pattern:
    /^(?!.*((.)\2{3,})|(^|\s)([\.\-\']+|and|aunt|auntie|aunty|best|big|brother|dad|daddie|daddio|daddy|dr|father|favourite|friend|gdad|gma|gramps|gran|grand|grand-dad|granda|grandad|granddad|grandma|grandpa|grandparents|grandson|granio|granny|grumpy|husband|mam|mama|mother|mum|mumma|mummy|nan|nanna|nannie|nanny|sex|sister|xx|xxx)(\s|$))/i,
};

export const regexSurnameExclusions: RegExpObject = {
  message: "Please only enter a formal surname.",
  pattern:
    /^(?!.*((.)\2{3,})|(^|\s)([\.\-\']+|and|aunt|auntie|aunty|banana hammock|bananahammock|dad|daddy|god|grampy|grand-dad|granda|grandad|grandma|mum|mummy|nan|nana|nanny|of nazareth|xx|xxx)(\s|$))/i,
};

export const regexEmail: RegExpObject = {
  message: "Please enter a valid email address.",
  pattern:
    /^(?=([a-z0-9!#$%&'*+\/=?^_`{|}~\-]+)((\.[a-z0-9!#$%&'*+\/=?^_`{|}~\-]+)*)).{1,64}@([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z][a-z]+$/i,
};

export const regexTelephone: RegExpObject = {
  message: "Phone number must contain only numbers, optionally starting with +",
  pattern: /^\+?[0-9]{10,16}$/,
};

// This isn't used downstream and it's possible to have a UK address but an international mobile
// export const regexTelephoneUk: RegExpObject = {
//   message: "Must begin with '07' or '01' or '02' and may only contain numbers.",
//   pattern: /^(0[127][0-9]*){1}[0-9]{9,15}$/,
// };

export const regexMobileTelephone: RegExpObject = {
  message: "Please enter a valid mobile number",
  pattern: /^(07|\+447|00447)[0-9]*$/,
};

// regexes specified by Siebel
export const regexAlphaNumericPlus: RegExpObject = {
  message:
    "may contain only letters, numbers, hyphens, spaces, full stops, slashes, apostrophes and ampersands.",
  pattern: /^([a-zA-Z0-9&\-\/\.'' ]|&#039;)*$/i,
};

export const regexAlphaPlus: RegExpObject = {
  message: "may contain only letters, hyphens, spaces, full stops and slashes",
  pattern: /^[a-zA-Z\-\/.' ]+$/i,
};

export const regexUkPostcode: RegExpObject = {
  message: "Please enter a valid UK postcode.",
  pattern:
    /^ *(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2})) *$/i,
};

export const regexInternationalPostcode: RegExpObject = {
  message: "may contain only letters, numbers, hyphens and spaces.",
  pattern: /^[a-zA-Z0-9 \-]+$/,
};

export const regexNameWithAnd: RegExpObject = {
  message: "should only contain letters, hyphens, spaces and apostrophes.",
  pattern:
    /^[a-zA-Z. '\-ƼƽÁáÀàȦȧÂâÄäǍǎĂăĀāÃãÅåĄąȺǠǡǺǻǞǟȀȁȂȃɐɑɒÆæǼǽǢǣƀɃƁɓƂƃƄƅĆćĊċĈĉČčÇçȻȼƇƈƆɔɕĎďĐđƋƌƊɗÐðƍȸǱǲǳǄǅǆƉɖȡÉéÈèĖėÊêËëĚěĔĕĒēĘęȨȩɆɇȄȅȆȇƐɛƎǝƏəɚɘɜɝɞȜȝƑƒǴǵĠġĜĝǦǧĞğĢģǤǥƓƔĤĥȞȟĦħƕǶıÍíÌìİÎîÏïǏǐĬĭĪīĨĩĮįƗȈȉȊȋƖĲĳȷĴĵǰɈɉɟĸǨǩĶķƘƙĹĺĿŀĽľĻļƚȽŁłƛǇǈǉȴƜŃńǸǹŇňÑñŅņƝŉƞȠǊǋǌȵŊŋÓóÒòȮȯÔôÖöǑǒŎŏŌōÕõǪǫŐőƟØøȰȱȪȫǾǿȬȭǬǭȌȍȎȏƠơƢƣŒœȢȣƤƥɊɋȹƦŔŕŘřŖŗɌɍȐȑȒȓŚśŜŝŠšŞşȘșȿƩƧƨƪßſŤťŢţƬƭƫƮȚțȾȶÞþŦŧÚúÙùÛûÜüǓǔŬŭŪūŨũŮůŲųŰűɄǗǘǛǜǙǚǕǖȔȕȖȗƯưƱƲɅŴŵƿǷÝýŶŷÿŸȲȳɎɏƳƴŹźŻżŽžƵƶȤȥɀƷǮǯƸƹƺƾɁɂ]+$/im,
};

export const RegexMoney: RegExpObject = {
  message:
    "Please enter a valid amount using numbers and a decimal point only. For example 100.00 or 2500.",
  pattern: /^[0-9]*(\.[0-9][0-9]?)*$/,
};

export const RegexYouTubeUrl: RegExpObject = {
  message: "Please enter a valid YouTube link.",
  pattern: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i,
};

export const RegexNoOnlyEmoji: RegExpObject = {
  message: "Name should not only contain emojis.",
  pattern: /^(?!(?:\p{Extended_Pictographic}|\p{Emoji_Component}|\s)+$).+/u,
};

/* eslint-enable no-useless-escape */
